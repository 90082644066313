import review2 from '../../../../../../public/landingimages/testimonial7.svg';
import review3 from '../../../../../../public/landingimages/testimonial2.webp';
import review4 from '../../../../../../public/landingimages/testimonial3.webp';
import review5 from '../../../../../../public/landingimages/testimonial6.svg';
import review6 from '../../../../../../public/landingimages/testimonial4.webp';
  
      const reviewSet2 = [
        {
          "theme_name": "reviewpics",
          "path": review3
        },
        {
          "theme_name": "reviewpics",
          "path": review2
        },
        {
          "theme_name": "reviewpics",
          "path": review6
        },
        {
          "theme_name": "reviewpics",
          "path": review4
        },
        {
          "theme_name": "reviewpics",
          "path": review5
        },
        {
          "theme_name": "reviewpics",
          "path": review3
        },
        {
          "theme_name": "reviewpics",
          "path": review2
        },
        {
          "theme_name": "reviewpics",
          "path": review6
        }
      ]
export default reviewSet2;