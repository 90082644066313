
import React from 'react'
//import dynamic from "next/dynamic";
//components
import Heropage from './heropage/heropage';
import Yourgame from './yourgame/yourgame';
import Newfriends from './newfriends/newfriends';
import Winrewards from './winrewards/winrewards';
import ReviewTitle from './reviewsByPlayers/reviewTitle';
import CardsTitle from './cards/cardsTitle/cardsTitle';
import VenuesList from './venuesList/venuesList';
import Footer from './footer/footer';
import Readytoplay from './ready_to_play/readytoplay';
import NavBar from '@/components/common/NavBar';
import CitiesOperated from '@/components/common/CitiesOperated';
import { pageType } from '@/routes/venues/venues';


const Landingpages_Mobile = () => {
  return (
    <>
      <NavBar />
      {/* <Navbar /> */}
      <Heropage />
      <Yourgame />
      <Newfriends />
      <Winrewards />
      <ReviewTitle />
      <CardsTitle />
      <VenuesList />
      <Readytoplay />
      <CitiesOperated Pagetype={pageType?.CITY} city={''} area={''} reload />
      <Footer />
    </>
  );
};

export default Landingpages_Mobile;
