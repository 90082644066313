import ground11 from '../../../../../public/landingimages/ground11.webp';
import ground22 from '../../../../../public/landingimages/ground22.webp';
import ground33 from '../../../../../public/landingimages/ground33.webp';
import ground44 from '../../../../../public/landingimages/ground44.webp';
import ground55 from '../../../../../public/landingimages/ground55.webp';

const venueSet2 = [
    {
      "theme_name": "venuepics",
      "path": ground11
    },
    {
      "theme_name": "venuepics",
      "path": ground22
    },
    {
      "theme_name": "venuepics",
      "path": ground33
    },
    {
      "theme_name": "venuepics",
      "path": ground44
    },
    {
      "theme_name": "venuepics",
      "path": ground55
    },
    {
      "theme_name": "venuepics",
      "path": ground11
    },
    {
      "theme_name": "venuepics",
      "path": ground22
    },
    {
      "theme_name": "venuepics",
      "path": ground33
    },
    {
      "theme_name": "venuepics",
      "path": ground44
    },
    {
      "theme_name": "venuepics",
      "path": ground55
    }
  ]

export default venueSet2;