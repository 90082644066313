import review1 from '../../../../../../public/landingimages/testimonial10.svg';
import review2 from '../../../../../../public/landingimages/testimonial7.svg';
import review3 from '../../../../../../public/landingimages/testimonial2.webp';
import review7 from '../../../../../../public/landingimages/testimonial5.webp';

const reviewSet1 = [
    {
      "theme_name": "reviewpics",
      "path": review1
    },
    {
      "theme_name": "reviewpics",
      "path": review7
    },
    {
      "theme_name": "reviewpics",
      "path": review2
    },
    {
      "theme_name": "reviewpics",
      "path": review3
    },
    {
      "theme_name": "reviewpics",
      "path": review1
    },
    {
      "theme_name": "reviewpics",
      "path": review7
    },
    {
      "theme_name": "reviewpics",
      "path": review2
    },
    {
      "theme_name": "reviewpics",
      "path": review3
    }
  ]

export default reviewSet1;
