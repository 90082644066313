
import ground1 from '../../../../../public/landingimages/newmg1.webp';
import ground2 from '../../../../../public/landingimages/newmg2.webp';
import ground3 from '../../../../../public/landingimages/newmg3.webp';
import ground4 from '../../../../../public/landingimages/newmg4.webp';
import ground5 from '../../../../../public/landingimages/newmg5.webp';
import ground6 from '../../../../../public/landingimages/newmg6.webp';

const VenueData1 = [
    {
        "theme_name": "venuepics",
        "path": ground1
    },
    {
        "theme_name": "venuepics",
        "path": ground2
    },
    {
        "theme_name": "venuepics",
        "path": ground3
    },
    {
        "theme_name": "venuepics",
        "path": ground4
    },
    {
        "theme_name": "venuepics",
        "path": ground5
    },
    {
        "theme_name": "venuepics",
        "path": ground6
    },
    {
        "theme_name": "venuepics",
        "path": ground1
    },
    {
        "theme_name": "venuepics",
        "path": ground2
    },
    {
        "theme_name": "venuepics",
        "path": ground3
    },
    {
        "theme_name": "venuepics",
        "path": ground4
    },
    {
        "theme_name": "venuepics",
        "path": ground5
    },
    {
        "theme_name": "venuepics",
        "path": ground6
    }
]

export default VenueData1;