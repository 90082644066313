import React from "react";
//styles
import classNames from "classnames";
import styles from "./reviewTitle.module.scss";
import "animate.css";
//images
import Image from "next/image";
//data
import  data  from './data'

const ReviewTitle = () => {

  return (
    <div className={styles.review_container}>
      <div className={classNames(styles.review_title)}>
        Loved by players all
      </div>
      <div className={classNames(styles.review_title2)}>
        around{" "}
        <span className="animate__animated animate__bounceIn animate__infinite animate__slow 1s">
          {" "}
          ❤️
        </span>
      </div>
      <div className={classNames(styles.review_top)}>
        <Image
          src={"/landingimages/loveflare.webp"}
          alt=""
          height={2}
          width={2}
        />
      </div>
      <div className={styles.container_overflow}>
        <div className={classNames(styles.animate_review)}>
          {data.map((data:any, key:number) => {
            return (
              <div key={key}>
                <div className={styles.container_review}>
                  <section className={classNames(styles.review_card)}>
                    <div className={classNames(styles.reviewer)}>
                      <Image
                        src={data.path}
                        alt=""
                        className={classNames(styles.reviewer_image)}
                      />
                      <div className={classNames(styles.reviewer_name)}>
                        {data.reviewer_name}
                        <div className={classNames(styles.reviewer_nickname)}>
                          {data.reviewer_nickname}
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        styles.reviewerdetails,
                    
                      )}
                    >
                      {data.review_details}
                    </div>
                    <div
                      className={classNames(
                        styles.reviewerdetails2,
                     
                      )}
                    >
                      {data?.review_detail2}
                    </div>
                  </section>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames(styles.review_bottom)}></div>
    </div>
  );
};

export default ReviewTitle;
