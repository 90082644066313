import React, { useEffect, useState } from 'react'
import { GetStaticProps } from 'next';
//constext
import Landingpages_Mobile from '@/components/landingpageComponents/landingpages_Mobile/landingpages_Mobile';
import Landingpage_desktop from '@/components/landingpageComponents/landingpage_Desktop/landingpage_desktop';
import Seo from '@/components/common/seo/Seo';

const Index = () => {

  const [isMobileView, setIsMobileView] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    checkViewport();
    window.addEventListener('resize', checkViewport);
    return () => {
      window.removeEventListener('resize', checkViewport);
    };
  }, []);

  setTimeout(() => {
    setLoading(false);
  }, 200);

  const checkViewport = () => {
    const isMobileView = window.matchMedia('(max-width: 767px)').matches;
    setIsMobileView(isMobileView);
  };

  return (
    <div>
      <Seo />
      {loading ? (
        <div style={{ height: "100vh", background: "#121212" }}></div>
      ) : (
        <>
          {isMobileView ? (
            <Landingpages_Mobile />
          ) : (
            <Landingpage_desktop />
          )}
        </>
      )}
    </div>)
}

export default Index

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};
