
//images
import ground11 from '../../../../../public/landingimages/newmg7.webp';
import ground22 from '../../../../../public/landingimages/newmg8.webp';
import ground33 from '../../../../../public/landingimages/newmg9.webp';
import ground44 from '../../../../../public/landingimages/newmg10.webp';
import ground55 from '../../../../../public/landingimages/newmg11.webp';

const VenueData2 = [
    {
        "theme_name": "venuepics",
        "path": ground11
    },
    {
        "theme_name": "venuepics",
        "path": ground22
    },
    {
        "theme_name": "venuepics",
        "path": ground33
    },
    {
        "theme_name": "venuepics",
        "path": ground44
    },
    {
        "theme_name": "venuepics",
        "path": ground55
    },
    {
        "theme_name": "venuepics",
        "path": ground11
    },
    {
        "theme_name": "venuepics",
        "path": ground22
    },
    {
        "theme_name": "venuepics",
        "path": ground33
    },
    {
        "theme_name": "venuepics",
        "path": ground44
    },
    {
        "theme_name": "venuepics",
        "path": ground55
    }
]
export default VenueData2;