import React from 'react';
//styles
import { motion } from 'framer-motion';
import styles from './venuesList.module.scss';
//images
import Image from 'next/image';
import shut from '../../../../../public/landingimages/shut.svg';
import dds from '../../../../../public/landingimages/dd.svg';
import whistle from '../../../../../public/landingimages/whistle.webp';
import tikitaka from '../../../../../public/landingimages/tikitaka.svg';
import fcmarina from '../../../../../public/landingimages/fcmarina.png';
import manrun from '../../../../../public/landingimages/manrun.png';

const VenueArea = () => {
    return (
        <>
            <div className={styles.venue_area}>
                <motion.div
                    initial={{ perspective: '400px', rotateX: 90, opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut', delay: 0 }}
                    whileInView={{ perspective: '400px', rotateX: 0, opacity: 1 }}
                    viewport={{ once: true }} >
                    <Image src={shut} alt="..." height={65} width={65} />
                </motion.div>

                <motion.div
                    initial={{ perspective: '400px', rotateX: 90, opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.1 }}
                    whileInView={{ perspective: '400px', rotateX: 0, opacity: 1 }}
                    viewport={{ once: true }} >
                    <Image src={dds} alt="..." height={68} width={68} />
                </motion.div>
                <motion.div
                    initial={{ perspective: '400px', rotateX: 90, opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.2 }}
                    whileInView={{ perspective: '400px', rotateX: 0, opacity: 1 }}
                    viewport={{ once: true }} >
                    <Image src={whistle} alt="..." height={60} width={60} style={{ marginTop: 4 }} />
                </motion.div>
                <motion.div
                    initial={{ perspective: '400px', rotateX: 90, opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.3 }}
                    whileInView={{ perspective: '400px', rotateX: 0, opacity: 1 }}
                    viewport={{ once: true }} >
                    <Image src={dds} alt="..." height={70} width={70} />
                </motion.div>
            </div>
            <div className={styles.venue_area1}>
                <motion.div
                    initial={{ perspective: '400px', rotateX: 90, opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.05 }}
                    whileInView={{ perspective: '400px', rotateX: 0, opacity: 1 }}
                    viewport={{ once: true }} >
                    <Image src={tikitaka} alt="..." height={60} width={60} />
                </motion.div>
                <motion.div
                    initial={{ perspective: '400px', rotateX: 90, opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.1 }}
                    whileInView={{ perspective: '400px', rotateX: 0, opacity: 1 }}
                    viewport={{ once: true }} >
                    <Image src={fcmarina} alt="..." height={60} width={60} />
                </motion.div>
                <motion.div
                    initial={{ perspective: '400px', rotateX: 90, opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.15 }}
                    whileInView={{ perspective: '400px', rotateX: 0, opacity: 1 }}
                    viewport={{ once: true }} >
                    <Image src={manrun} alt="..." height={60} width={60} />
                </motion.div>
            </div>
        </>
    )
}

export default VenueArea;