import React from "react";
//styles
import classNames from "classnames";
import styles from './winrewards.module.scss';
import "animate.css";
import { motion } from 'framer-motion';
//images
import Image from "next/image";

const Winrewards = () => {
  return (
    <>
      <motion.div animate={{ y: 0, opacity: 0 }}
        transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2, delay: 0.15 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        className={classNames(styles.container_secondpage, styles["mb-20"], styles["mt-96"])}>
        <h2 className={classNames(styles.game_always)}>Win <span >{" "} rewards </span> as <br /> you play</h2>

        <div className={classNames(styles.ready_content, styles['mt-20'])}>
          Earn Turf Coins by playing games and
        </div>
        <div className={classNames(styles.ready_content, styles['mb-48'])}>
          redeem them for more play time.
        </div>
      </motion.div>
      <motion.div animate={{ y: 0, opacity: 0 }}
        transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2, delay: 0.15 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        className={classNames(styles.container_secondpage_image, styles["mb-100"])}>
        <Image src={'/landingimages/orangeman.webp'} alt="" height={374} width={340}
          className='animate__animated animate__fadeIn' />
      </motion.div>
    </>
  )
}

export default Winrewards;