import review2 from '../../../../../../public/landingimages/testimonial7.svg';
import review3 from '../../../../../../public/landingimages/testimonial2.webp';
import review5 from '../../../../../../public/landingimages/testimonial6.svg';
import review6 from '../../../../../../public/landingimages/testimonial4.webp';
import review7 from '../../../../../../public/landingimages/testimonial5.webp';
import review8 from '../../../../../../public/landingimages/testimonial8.svg';
import review9 from '../../../../../../public/landingimages/testimonial9.svg';

const reviewSet3 = [
    {
      "theme_name": "reviewpics",
      "path": review8
    },
    {
      "theme_name": "reviewpics",
      "path": review9
    },
    {
      "theme_name": "reviewpics",
      "path": review2
    },
    {
      "theme_name": "reviewpics",
      "path": review3
    },
    {
      "theme_name": "reviewpics",
      "path": review5
    },
    {
      "theme_name": "reviewpics",
      "path": review7
    },
    {
      "theme_name": "reviewpics",
      "path": review2
    },
    {
      "theme_name": "reviewpics",
      "path": review6
    }
  ]
export default reviewSet3;