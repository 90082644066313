import React from 'react';
//styles
import styles from './venuesList.module.scss';
import classNames from 'classnames';
import 'animate.css';
//components
import VenueArea from './venueArea';
//Images
import Image from 'next/image';
//datas
import venueData1 from './venueData1';
import venueData2 from './venueData2';

const VenuesList = () => {

    return (
        <>
            <div className={styles.venues_background}>
                <div className={classNames(styles.venues_container)}>
                    <div className={classNames(styles.venues_title)}>
                        Home to some of the
                    </div>
                    <div className={classNames(styles.venues_title1,)}>
                        biggest venues in Town
                    </div>
                </div>
                <>
                    <VenueArea />
                </>
                <div className={classNames(styles.animate_container)}>
                    <div className={styles.animate_venue_images}>
                        {
                            venueData1.map((data, key) => {
                                return (
                                    <div key={key}>
                                        <Image src={data.path} alt='venuegrounds' height={84} width={133} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={classNames(styles.animate_container1)}>
                    <div className={styles.animate_venue_images2}>
                        {
                            venueData2.map((data, key) => {
                                return (
                                    <div key={key}>
                                        <Image key={key} src={data.path} alt='venuegrounds' height={84} width={133} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default VenuesList;