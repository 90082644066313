import React from 'react';
//image
import Image from 'next/image';
import purplelines from '../../../../../public/landingimages/purplevector_optimized.webp';
import purplemen from '../../../../../public/landingimages/makenewfriends.webp';
//styles
import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './makenewfriends_desktop.module.scss'

const Makenewfriends_desktop = () => {
    return (
        <>
            <div className='container'>
                <div className={classNames(styles.ready_game)} >
                    <div className={classNames(styles.col_1)}>
                    </div>
                    <div className={classNames(styles.col_5)}>
                        <motion.div animate={{ y: 100, opacity: 0 }}
                            transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2, delay: 0.15 }}
                            whileInView={{ y: -10, opacity: 1 }}
                            viewport={{ once: true }}
                            className={styles.yourgame_text_alignment}>

                            <div className={styles.game_always}>
                                <h2 className={styles.game_always_text}>
                                    Make new<span className={styles.bluefriends}> friends</span> <br />
                                    on court
                                </h2>
                            </div>
                            <div className={styles.game_always}>

                            </div>
                            <p className={styles.explain1}>
                                Meet interesting players who share your
                                love for the game and grow your
                                sports circle!
                            </p>
                            <p className={styles.explain}>
                                Meet interesting players who share your love<br />
                                for the game and grow your sports circle!<br />
                            </p>

                        </motion.div>
                    </div>
                    <div className={classNames(styles.col_5)}>
                        <motion.div animate={{ y: 100, opacity: 0 }}
                            transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2, delay: 0.15 }}
                            whileInView={{ y: -10, opacity: 1 }}
                            viewport={{ once: true }} className={styles.makefriendsImageAlignment}>
                            <Image className={classNames(styles.purplelines)} src={purplelines} alt='play' loading='lazy' />
                            <Image className={classNames(styles.purpleman)} src={purplemen} alt='play' loading="lazy" style={{ zIndex: 1, position: "absolute" }} />
                        </motion.div>
                        <br /><br />
                    </div>
                    <div className={classNames(styles.col_1)}>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Makenewfriends_desktop