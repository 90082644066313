import React, { ReactNode, useCallback, useEffect } from "react";
import Head from "next/head";

interface Props {
  title?: string;
  meta_title?: string;
  description?: string;
  heading?: string;
  meta_image_url?: string;
  url?: string;
  app_url?: string;
  alternate_link?: string;
  latlong?: string[];
  children?: ReactNode;
  structuredData?: boolean;
  imageUrls?: string[];
  breadcrumbList?: string;
  ItemList?: string;
  localBusiness?: string;
  itemList?: string;
}

const Seo = ({
  title = "Turf Town | India’s #1 Sports community",
  meta_title = "",
  description = "Book venues, find players and discover games in your neighbourhood. Join today and start playing!",
  heading = "",
  meta_image_url = "",
  url = "https://turftown.in/",
  alternate_link = "",
  app_url = "",
  latlong,
  structuredData = false,
  imageUrls = [],
  children,
  breadcrumbList,
  ItemList,
  localBusiness
}: Props): JSX.Element => {

  let org = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://turftown.in/",
    "name": "Turf Town",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Chennai, India",
      "postalCode": "600010",
      "streetAddress": "17, Lakshmi Street",
    },
    "logo": "https://turftown.s3.ap-south-1.amazonaws.com/super_admin/tt-1714392256191.webp",
    "email": "support@turftown.in",
    "description": "Description of your organization",
    "sameAs": [
      "https://www.instagram.com/turftown.in/?hl=en",
      "https://twitter.com/Turf_Town",
      "https://www.linkedin.com/company/turf-town/",
      "https://www.facebook.com/turftown.in/"
    ],
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+919940526080",
      "contactType": "customer service",
      "areaServed": "IN",
    }]
  }

  let web = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Turf Town",
    "url": "https://turftown.in/"
  }

  return (
    <Head>

      {localBusiness && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: localBusiness }} />}
      {breadcrumbList && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: breadcrumbList }} />}
      {ItemList && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ItemList }} />}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(web) }} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(org) }} />
      <title>{meta_title || title}</title>
      <link rel="icon" href="/images/favicon.ico" />
      {imageUrls.length > 0 && (
        <link key={0} rel="preload" href={imageUrls[0]} as="image" />
      )}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/images/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/images/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/images/favicon-16x16.png"
      />
      {/* <link rel="manifest" href="/site.webmanifest" /> */}
      <link rel="alternate" href={alternate_link} />
      <link rel="canonical" href={url} />
      <meta name="robots" content="noodp" />
      <meta charSet="UTF-8" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="white" />
      <meta name="title" content={meta_title} key="title" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1"
      />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={meta_title} />
      <meta property="og:description" content={description} />
      <meta property="og:heading" content={heading} />
      <meta property="og:url" content={url} />
      <meta property="og:image" itemProp="image" content={meta_image_url} />
      <meta property="og:image:alt" content={meta_title} />
      <meta property="og:image:secure_url" content={meta_image_url} />
      <meta property="og:image" content={meta_image_url} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="800" />
      <meta property="al:ios:app_store_id" content="427510738871044" />
      <meta property="al:ios:url" content={"https://onelink.to/turftown"} />
      <meta property="al:ios:app_name" content="turftown" />
      <meta property="al:android:package" content="com.turftown" />
      <meta property="al:android:url" content={"https://play.google.com/store/apps/details?id=com.turftown&hl=en_US"} />
      <meta property="al:android:app_name" content="turftown" />
      <meta name="twitter:site" content="@Turf_Town" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={meta_title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={meta_image_url} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Turf Town" />
      <meta name="twitter:image:alt" content={meta_title} />
      <meta property="twitter:image:width" content="1200" />
      <meta property="twitter:image:height" content="800" />
      <meta property="og:locale" content="en_US" />
      {latlong && <meta property="place:location:latitude" content={latlong[0]} />}
      {latlong && <meta property="place:location:longitude" content={latlong[1]} />}
      <noscript>
      </noscript>
      {children}
    </Head>
  );
};

export default Seo;
