import ground1 from '../../../../../public/landingimages/ground1test.webp';
import ground2 from '../../../../../public/landingimages/ground2.webp';
import ground3 from '../../../../../public/landingimages/ground3.webp';
import ground4 from '../../../../../public/landingimages/ground4.webp';
import ground5 from '../../../../../public/landingimages/ground5.webp';
import ground6 from '../../../../../public/landingimages/ground2test.webp';

const venueSet1 = [
    {
      "theme_name": "venuepics",
      "path": ground1
    },
    {
      "theme_name": "venuepics",
      "path": ground2
    },
    {
      "theme_name": "venuepics",
      "path": ground3
    },
    {
      "theme_name": "venuepics",
      "path": ground4
    },
    {
      "theme_name": "venuepics",
      "path": ground5
    },
    {
      "theme_name": "venuepics",
      "path": ground6
    },
    {
      "theme_name": "venuepics",
      "path": ground1
    },
    {
      "theme_name": "venuepics",
      "path": ground2
    },
    {
      "theme_name": "venuepics",
      "path": ground3
    },
    {
      "theme_name": "venuepics",
      "path": ground4
    },
    {
      "theme_name": "venuepics",
      "path": ground5
    },
    {
      "theme_name": "venuepics",
      "path": ground6
    }
  ]


  export default venueSet1;