import React from 'react';
//image
import Image from 'next/image';
//styles
import classNames from 'classnames';
import styles from './review_slide.module.scss';
//data
import reviewSet1 from './reviewSet1';
import reviewSet2 from './reviewSet2';
import reviewSet3 from './reviewSet3';

const Reviews_slide = () => {

  return (
    <>
    <div className={classNames(styles.row, styles.animate_container)} id='newreviews'>
        <div className={styles.scroll_container_new}>
      <div className={classNames(styles.col_4, styles.marquee1)} id='marquee1'>
        {
          reviewSet1.map((data, key) => {
            return (
                <Image key={key} src={data.path} alt='venuegroundsview' loading='lazy' className={styles.desktopreviews}/>
            )
          })
        }
      </div>
      <div className={classNames(styles.col_4, styles.marquee2)} id='marquee2'>
        {
          reviewSet2.map((data, key) => {
            return (
                <Image key={key} src={data.path} alt='venuegroundsview' loading='lazy' className={styles.desktopreviews}/>
            )
          })
        }
      </div>
      <div className={classNames(styles.col_4, styles.marquee3)} id='marquee3'>
        {
          reviewSet3.map((data, key) => {
            return (
                <Image key={key} src={data.path} alt='venuegroundsview' loading='lazy' className={styles.desktopreviews}/>
            )
          })
        }
      </div>
      </div>
    </div>
    </>
  )
}

export default Reviews_slide;