import React from 'react';
//Images
import Image from 'next/image';
import shut from '../../../../../public/landingimages/shut.svg';
import dd from '../../../../../public/landingimages/dd.svg';
import whistle from '../../../../../public/landingimages/whistle.webp';
import tikitaka from '../../../../../public/landingimages/tikitaka.svg';
import fcmarina from '../../../../../public/landingimages/fcmarina.png';
import manrun from '../../../../../public/landingimages/manrun.png';
//styles
import styles from './venue_animate.module.scss'
import classNames from 'classnames';
//data
import VenueSet1 from './venueSet1';
import VenueSet2 from './venueSet2';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css';

const Venue_animate = () => {

  return (
    <div className={classNames(styles.venue_animate_desktop_background)}>
      <div className="container">
        <div className={styles.venue_container_row}>
          <div className={styles.col_1}>
          </div>
          <div className={classNames(styles.col_12, styles.venue_top_alignment)}>
            <div className={classNames(styles.venue_text_alignment)}>
              <div className={styles.venue_container_row2}>
                <div className={styles.col_1}>
                </div>
                <div className={classNames(styles.col_10)}>
                  <div className={styles.biggestvenue}>
                    <div className={styles.weare}> Home to some of the</div>
                    <div className={styles.weare}> biggest venues in Town.</div>  </div>
                </div>
                <div className={styles.col_1}>
                </div>
              </div>
            </div>
            <div className={styles.venue_animate_logo}>
            <ScrollAnimation animateIn='animate__zoomInDown animate__slow '
                duration={0.8}
                animateOnce={true}>
                <Image src={shut} className={styles.venuelogo} alt="..." loading="lazy" />
              </ScrollAnimation>

              <ScrollAnimation animateIn='animate__zoomInDown animate__slow '
                duration={1}
                animateOnce={true}>
                <Image src={dd} className={styles.venuelogo} alt="..." loading="lazy" />
              </ScrollAnimation>

              <ScrollAnimation animateIn='animate__zoomInDown animate__slow '
                duration={1.2}
                animateOnce={true}>
                <Image src={whistle} className={styles.venuelogo1} alt="..." loading="lazy" />
              </ScrollAnimation>

              <ScrollAnimation animateIn='animate__zoomInDown animate__slow '
                duration={1.4}
                animateOnce={true}>
                <Image src={tikitaka} className={styles.venuelogo1} alt="..." loading="lazy" />
              </ScrollAnimation>

              <ScrollAnimation animateIn='animate__zoomInDown animate__slow '
                duration={1.6}
                animateOnce={true}>
                <Image src={fcmarina} className={styles.venuelogo1} alt="..." loading="lazy" />
              </ScrollAnimation>

              <ScrollAnimation animateIn='animate__zoomInDown animate__slow '
                duration={1.4}
                animateOnce={true}>
                <Image src={manrun} className={styles.venuelogo1} alt="..." loading="lazy" />
              </ScrollAnimation>
            </div>
          </div>
          <div className={styles.col_1}>
          </div>
        </div>
      </div>
      <div className={styles.venue_animate_1}>
        {
          VenueSet1.map((data, key) => {
            return (
                <Image key={key} src={data.path} alt='venuegroundsview' loading='lazy' className={styles.desktopground} />
            )
          })
        }
      </div>
      <div className={styles.venue_animate_2} >
        {
          VenueSet2.map((data, key) => {
            return (
                <Image key={key} src={data.path} alt='venuegroundsview' loading='lazy' className={styles.desktopground2} />
            )
          })
        }
      </div>
    </div>
  )
}

export default Venue_animate;